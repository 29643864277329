


















































import { Global } from '@/store/globalz';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class SummonTips extends Vue {
  public baseUrl = process.env.BASE_URL;
  public onShop() {
    Global.shopModal.show();
  }
}
