
export const xsollaOrderCodes = [
	'bp-200',
	'bp-400',
	'bp-2100',
	'bp-4400',
	'bp-11200',
	'bp-30000',
	'bp-56000',
	'hat-of-fortune',
] as const;
export type XsollaOrderCode = typeof xsollaOrderCodes[number];
export interface XsollaOrderInfo {
	cost: string,
	amount: number,
	code: XsollaOrderCode,
}
export const xsollaPurchaseInfos: { [T in XsollaOrderCode]: XsollaOrderInfo } = {} as any;


function setInfo(code: XsollaOrderCode, cost: string, amount: number) {
	xsollaPurchaseInfos[code] = {
		code,
		cost,
		amount,
	};
}

setInfo('bp-200', '0.50', 200);
setInfo('bp-400', '0.99', 400);
setInfo('bp-2100', '4.99', 2100);
setInfo('bp-4400', '9.99', 4400);
setInfo('bp-11200', '24.99', 11200);
setInfo('bp-30000', '59.99', 30000);
setInfo('bp-56000', '99.99', 56000);
setInfo('hat-of-fortune', '1.99', 1);
