





























































import { SoundEfx } from '@/client/sound/SoundEfx';
import {
	blackPearlPurchaseInfos,
	BlackPearlPurchaseType,
} from '@/game/infos/blackPearlInfos';
import { Global } from '@/store/globalz';
import userx from '@/store/modules/userx';
import { BModal } from 'bootstrap-vue';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import CurrencyBar from '../gacha/CurrencyBar.vue';
import { authorizePoq, buyQuarters } from '../gacha/poq/poqFunctions';
import BlackPearlButton from './BlackPearlButton.vue';
import { xsollaOrderCodes } from '@/game/infos/xsollaPurchaseInfos';

@Component({
	components: {
		CurrencyBar,
		BlackPearlButton,
	},
})
export default class ShopModal extends Vue {
	public baseUrl = process.env.BASE_URL;
	public showLoading = false;
	public created() {
		Global.shopModal = this;
	}

	public show() {
		(this.$refs.myModal as BModal).show();
	}

	public buyQuarters() {
		buyQuarters();
	}
	public async onBuyBlackPearl(type: number) {
		// if (!userx.userDoc.poq) {
		//   Global.poqLinkModal.show();
		//   return;
		// }
		// const info = blackPearlPurchaseInfos[type];
		// const h = this.$createElement;
		// if (userx.poqWallet.balance < info.cost) {
		//   (this.$refs.poqModal as BModal).show();
		//   return;
		// }
		// const messageVNode = h('div', {}, [
		//   'Purchase with ',
		//   h('img', {
		//     style: 'height: 25px',
		//     class: 'icon-quarters',
		//     domProps: { src: `${this.baseUrl}/images/icons/quarters.svg?v=1` },
		//   }),
		//   h('span', { class: 'fwb' }, [` x ${info.cost}`]),
		//   ' ?',
		// ]);
		// const value = await this.$bvModal.msgBoxConfirm([messageVNode], {
		//   titleHtml: `Confirm Purchase`,
		//   size: 'sm',
		//   buttonSize: 'sm',
		//   okVariant: 'primary',
		//   cancelVariant: 'link btn-link',
		//   modalClass: 'funny-modal',
		//   okTitle: 'Yes',
		//   cancelTitle: 'Cancel',
		//   footerClass: 'p-2',
		//   hideHeaderClose: false,
		//   centered: true,
		// });
		// if (!value) {
		//   return;
		// }
		this.showLoading = true;
		try {
			const result = await userx.order(xsollaOrderCodes[type]);
			this.showLoading = false;
			//   new SoundEfx('buyChaching').play();
			//   this.$bvModal.msgBoxOk(`Received ${result.amount} Black Pearls!`, {
			//     titleHtml: `<i class="text-green fa fas fa-check-circle"></i> Succeed!`,
			//     size: 'sm',
			//     buttonSize: 'sm',
			//     okVariant: 'primary',
			//     modalClass: 'funny-modal',
			//     centered: true,
			//   });
		} catch (error) {
			this.showLoading = false;

			const err = error as any;
			if (err.statusCode) {
				this.showError(err.errorMessage);
			} else {
				this.showError(error);
			}
		}
	}

	public showError(error: any) {
		this.$root.$emit('error', error);
	}
}
