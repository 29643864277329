import userx from '@/store/modules/userx';
import { FirebaseSocket } from './firestore';
// import { SetSupportLvlResponse, Signature } from '@/store/models.def';
import globalx from './modules/globalx';
// import { TERMS_VERSION, PRIVACY_POLICY_VERSION } from '@/components/auth/termsPolicy/version';
import { PoqIndentity, PoqWallet, StabfishAccountData, StabfishAccountInventory, StabfishSystemDoc, StabfishTeamDoc, StabfishUserSettings } from '@/game/infos/firestoreFiles';
import { isJSDocThisTag } from 'typescript';
import { ChangeTeamLeaderRequest, ClaimQuestRequest, ConvertAllDuplicateSkinsRequest, ConvertDuplicateSkinsRequest, DecoContestRequest, DecoContestResult, DecoContestSubmission, DecoContestVotableEntry, DecoModAction, DecoVoteAction, EquipSkinRequest, GachaRequest, JoinTeamRequest, PeaceModeVote, PoqConnectRequest, ShopLimitedRequest } from '@/game/infos/dataServerPackets';
import { StabfishError } from '@/util/error';
import { FishType } from '@/game/infos/fishInfos';
import { ServerList } from './api/serverList';
import { ServerTime } from './api/serverTime';
import { ItemCode } from '@/game/infos/itemInfos';
import { BlackPearlPurchaseInfo, BlackPearlPurchaseType } from '@/game/infos/blackPearlInfos';
import { SkinBannerType } from '@/game/infos/skinBannerInfos';
import { SkinGroup } from '@/game/infos/skinInfos';
import { FirebaseStorage } from '@firebase/storage';
import { Functions } from '@firebase/functions';
import { FieldValue, Firestore, arrayRemove, doc as dbdoc, onSnapshot, setDoc, updateDoc } from '@firebase/firestore';
import { Auth, signInWithCustomToken } from '@firebase/auth';
import { Dictionary } from '@/util/dictionary';

let auth: Auth;
let db: Firestore;
let functions: Functions;
let storage: FirebaseStorage;

let serverTimeSynced = false;
let serverListSynced = false;

export class StabfishFirebaseSocket extends FirebaseSocket {
	public static hasMaintenance() {
		return new Promise((resolve: (value: boolean) => void) => {
			if (process.env.NODE_ENV === 'development' ||
				location.hostname === 'test8845.stabfish2.io') { resolve(false); }
			if (globalx.maintenanceInfo) {
				resolve(globalx.maintenanceInfo.ongoing);
			} else {
				StabfishFirebaseSocket._maintenancePromises.push(resolve);
			}

		});
	}

	private static _maintenancePromises: Array<(hasMaintenance: boolean) => void> = [];

	public unsubUserDataDocListener: (() => void) | null = null;
	public unsubUserInventoryDocListener: (() => void) | null = null;
	public unsubUserSettingsDocListener: (() => void) | null = null;
	public unsubTeamDocListener: (() => void) | null = null;

	protected currentTeamId = '';


	public constructor() {
		super('none');
		auth = this.auth;
		db = this.db;
		functions = this.functions;
		storage = this.storage;

		onSnapshot(dbdoc(db, 'games/stabfish2'), (doc) => {
			let hasMaintenance = false;
			if (doc.exists()) {
				const data = doc.data()! as StabfishSystemDoc;
				globalx.updateMaintenanceInfo(data.maintenance);
				hasMaintenance = process.env.NODE_ENV !== 'development' &&
					location.hostname !== 'test8845.stabfish2.io' && data.maintenance?.ongoing || false;
				const latestVersion = data.latestVersion;
				globalx.updateLatestVersion(data.latestVersion);

			} else {
				globalx.updateMaintenanceInfo();
			}
			for (const resolve of StabfishFirebaseSocket._maintenancePromises) {
				resolve(hasMaintenance);
			}
			if (!hasMaintenance) {
				if (!serverTimeSynced) {
					serverTimeSynced = true;
					ServerTime.sync().then(() => {
						globalx.setServerTimeReady();
					});
				}
				if (!serverListSynced) {
					serverListSynced = true;
					ServerList.sync();
				}
			}
			StabfishFirebaseSocket._maintenancePromises.length = 0;
		}, (error) => {
			for (const resolve of StabfishFirebaseSocket._maintenancePromises) {
				resolve(true);
			}
		});
	}

	public async logoutUser() {
		if (this.unsubUserDataDocListener) {
			this.unsubUserDataDocListener();
			this.unsubUserDataDocListener = null;
		}
		if (this.unsubUserInventoryDocListener) {
			this.unsubUserInventoryDocListener();
			this.unsubUserInventoryDocListener = null;
		}
		if (this.unsubUserSettingsDocListener) {
			this.unsubUserSettingsDocListener();
			this.unsubUserSettingsDocListener = null;
		}
		this.updateTeam('');
		super.logoutUser();
	}

	public async updateDisplayName(name: string) {
		if (!auth.currentUser) {
			throw new StabfishError('auth/requires-login');
		}
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser.getIdToken(true);
		try {

			const response = await fetch(`${dataServerAddress}/username?v=1`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify({
					name,
				}),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json();
			return json.name;
		} catch (error) {
			throw error;
		}

	}

	public async updateTeam(teamId: string) {
		if (this.currentTeamId === teamId) {
			return;
		}
		if (this.unsubTeamDocListener) {
			this.unsubTeamDocListener();
			this.unsubTeamDocListener = null;
		}
		if (teamId) {
			this.unsubTeamDocListener = onSnapshot(dbdoc(db, `games/stabfish2/teams/${teamId}`), (doc) => {
				if (doc.exists()) {
					userx.updateTeamData(doc.data() as any);
				}
			});
		} else {
			userx.updateTeamData(null);
		}
		this.currentTeamId = teamId;
	}

	public async createTeam(passcode: string) {
		if (!auth.currentUser) {
			throw new StabfishError('auth/requires-login');
		}
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser.getIdToken(true);
		try {

			const response = await fetch(`${dataServerAddress}/team?v=1`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify({
					passcode,
				}),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as StabfishTeamDoc;
			this.updateTeam(json.tid);
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async changeTeamPasscode(passcode: string) {
		if (!auth.currentUser) {
			throw new StabfishError('auth/requires-login');
		}
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser.getIdToken(true);
		try {

			const response = await fetch(`${dataServerAddress}/teamPasscode?v=1`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify({
					passcode,
				}),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as StabfishTeamDoc;
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async changeTeamLeader(request: ChangeTeamLeaderRequest) {
		if (!auth.currentUser) {
			throw new StabfishError('auth/requires-login');
		}
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser.getIdToken(true);
		try {

			const response = await fetch(`${dataServerAddress}/teamLeader?v=1`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify(request),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as StabfishTeamDoc;
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async joinTeam(request: JoinTeamRequest) {
		if (!auth.currentUser) {
			throw new StabfishError('auth/requires-login');
		}
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {

			const response = await fetch(`${dataServerAddress}/teamMember?v=1`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify(request),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as StabfishTeamDoc;
			this.updateTeam(json.tid);
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async leaveTeam() {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			this.updateTeam('');
			const response = await fetch(`${dataServerAddress}/teamMember?v=1`, {
				method: 'DELETE',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
			});
			if (!response.ok) {
				throw await response.json();
			}
		} catch (error) {
			throw error;
		}
	}
	public async kickMember(uid: string) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/teamMember?v=1`, {
				method: 'DELETE',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify({ uid }),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as StabfishTeamDoc;
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async unlockFish(fishType: FishType) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/unlockFish?v=1`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify({ fishType }),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as { success: boolean; };
			return json.success;
		} catch (error) {
			throw error;
		}
	}
	public async craft(blueprint: ItemCode) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/craft?v=1`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify({ blueprint }),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as StabfishAccountInventory;
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async contribute(challengeId: string) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/contribute?v=1`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify({ challengeId }),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as { success: boolean; };
			return json.success;
		} catch (error) {
			throw error;
		}
	}
	public async questPay(challengeId: string) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/questPay?v=1`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify({ challengeId }),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as { success: boolean; };
			return json.success;
		} catch (error) {
			throw error;
		}
	}
	public async claimQuest(submission: ClaimQuestRequest) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/questClaim?v=1`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify(submission),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as { success: boolean; };
			return json.success;
		} catch (error) {
			throw error;
		}
	}
	public async sortBag() {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/sortBag?v=1`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as StabfishAccountInventory;
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async readMail(mailId: string) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/readMail/${mailId}?v=1`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as { success: boolean; };
			return json.success;
		} catch (error) {
			throw error;
		}
	}
	public async updateDecoSet(fishType: FishType, decoSet: string) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/decoSet/${fishType}?v=1`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify({ decoSet }),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as StabfishAccountInventory;
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async increaseDecoLimit(toLimit: number) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/increaseDecoLimit/${toLimit}?v=1`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as { decoLimit: number; };
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async linkPoq(submission: PoqConnectRequest) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/poq?v=1`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify(submission),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as PoqIndentity;
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async getPoqWallet() {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/poqWallet?v=1`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as PoqWallet;
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async cnyPurchase(itemCode: ItemCode) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/cnyPurchase/${itemCode}?v=1`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as BlackPearlPurchaseInfo;
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async order(code: string) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/xsolla/order/${code}?v=1`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify({returnUrl: window.location.href}),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as { token: string, order_id: number; };
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async buyBlackPearl(type: BlackPearlPurchaseType) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/blackPearls?v=1`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify({ type }),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as BlackPearlPurchaseInfo;
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async buyShopLimited(submission: ShopLimitedRequest) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/shopLimited?v=1`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify(submission),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as Dictionary<number>;
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async convertDuplicateSkins(submission: ConvertDuplicateSkinsRequest) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/convertDuplicateSkins?v=1`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify(submission),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as BlackPearlPurchaseInfo;
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async convertAllDuplicateSkins(submission: ConvertAllDuplicateSkinsRequest) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/convertAllDuplicateSkins?v=1`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify(submission),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as BlackPearlPurchaseInfo;
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async gachaSkins(submission: GachaRequest) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/gachaSkins?v=1`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify(submission),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as number[];
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async votePeaceMode(submission: PeaceModeVote) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/vote/peaceMode?v=1`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify(submission),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as number[];
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async equipSkin(submission: EquipSkinRequest) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/equippedSkin?v=1`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify(submission),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as { succeed: boolean; };
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async decoContest(contestId: number, submission: DecoContestRequest) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/decoContest/${contestId}?v=1`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify(submission),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as DecoContestSubmission;
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async getDecoEntriesForVote(contestId: number, amount: number) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/decoContestVotables/${contestId}/${amount}?v=1`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as DecoContestVotableEntry[];
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async getDecoContestResult(contestId: number, published: boolean) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/decoContestResult/${contestId}/${published}?v=1`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as DecoContestResult;
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async getDecoEntriesForMod(contestId: number, limit: number, action: DecoModAction | string) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/decoContestEntries/${contestId}/${limit}/${action}?v=1`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as DecoContestVotableEntry[];
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async actionOnDecoContest(contestId: number, target: string, action: DecoVoteAction) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/decoContestVotables/${contestId}/${target}/${action}?v=1`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json();
			return json;
		} catch (error) {
			console.error(error);
			throw error;
		}
	}

	public async claimDecoContestPrize(id: string) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/decoContestPrize/${id}?v=1`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as { success: boolean; };
			return json.success;
		} catch (error) {
			throw error;
		}
	}
	public async claimAccuRewards(id: string) {
		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const response = await fetch(`${dataServerAddress}/accuRewards/${id}?v=1`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as Dictionary<number>;
			return json.success;
		} catch (error) {
			throw error;
		}
	}


	public async signInWithCrazyGamesToken(token: string) {
		// my cg accountId: Ew0hB2ikzxczCoIEg3BAeaMhMPp2
		try {
			const dataServerAddress = globalx.dataServerAddress;
			const response = await fetch(`${dataServerAddress}/signin/crazyGames`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as { token: string; };
			signInWithCustomToken(auth, json.token);
		} catch (error) {
			throw error;
		}
	}
	public async linkWithCrazyGamesToken(crazyGamesToken: string) {
		// my cg accountId: Ew0hB2ikzxczCoIEg3BAeaMhMPp2
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const dataServerAddress = globalx.dataServerAddress;
			const response = await fetch(`${dataServerAddress}/credentials/crazyGames`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify({ crazyGamesToken }),
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as { crazyGames: string; };
			return json.crazyGames;
		} catch (error) {
			throw error;
		}
	}
	public async unlinkWithCrazyGames() {
		// my cg accountId: Ew0hB2ikzxczCoIEg3BAeaMhMPp2
		const token = await auth.currentUser!.getIdToken(true);
		try {
			const dataServerAddress = globalx.dataServerAddress;
			const response = await fetch(`${dataServerAddress}/credentials/crazyGames`, {
				method: 'DELETE',
				headers: {
					'Authorization': 'Bearer ' + token,
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json() as { success: boolean; };
		} catch (error) {
			throw error;
		}
	}

	// public async forceLogin(uid: string) {
	// 	const token = await auth.currentUser!.getIdToken(true);
	// 	try {
	// 		const response = await fetch(`http://localhost:9001/forceLogin/${uid}`, {
	// 			method: 'PATCH',
	// 			headers: {
	// 				'Authorization': 'Bearer ' + token,
	// 				'Content-type': 'application/json',
	// 				'accept': 'application/json',
	// 			},
	// 		});
	// 		if (!response.ok) {
	// 			throw await response.json();
	// 		}
	// 		const json = await response.json() as { token: string; };
	// 		console.log(json.token);
	// 		auth.signInWithCustomToken(json.token);
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// }

	public async changeUserSettings(settings: Partial<StabfishUserSettings>) {
		return setDoc(dbdoc(db, `games/stabfish2/users/${this.uid}/docs/sf2settings`), settings, { merge: true });
	}
	public async readUnlock(unlockIds: string[]) {
		return updateDoc(dbdoc(db, `games/stabfish2/users/${this.uid}/docs/sf2settings`), {
			completedChallenges: arrayRemove(...unlockIds),
		});
	}
	protected async serverMergeUsers(anonymousToken: string, targetToken: string) {

		const lobbyServerAddress = globalx.lobbyServerAddress;
		try {

			const response = await fetch(`${lobbyServerAddress}/mergeUsers?v=1`, {
				method: 'PATCH',
				headers: {
					// 'Authorization': 'Bearer '+token
					'Content-type': 'application/json',
					'accept': 'application/json',
				},
				body: JSON.stringify({
					anonymousToken,
					targetToken,
				}),
			});
			const json = await response.json();
		} catch (error) {
			throw error;
		}
	}
	protected async _readExtraDocs(userObject: any) {
		const hasMaintenance = await StabfishFirebaseSocket.hasMaintenance();
		if (hasMaintenance) { return; }

		globalx.setUserDocsReady(false);
		let docReadCount = 3;

		function readDoc() {
			docReadCount--;
			if (docReadCount === 0) {
				globalx.setUserDocsReady(true);
			}
		}

		const dataServerAddress = globalx.dataServerAddress;
		const token = await auth.currentUser!.getIdToken(true);
		const { uid, isAnonymous } = userObject;
		await fetch(`${dataServerAddress}/login?v=1`, {
			method: 'POST',
			headers: {
				'Authorization': 'Bearer ' + token,
				'Content-type': 'application/json',
				'accept': 'application/json',
			},
			body: JSON.stringify({ nonAnonymous: !isAnonymous }),
		});

		this.unsubUserDataDocListener = onSnapshot(dbdoc(db, `games/stabfish2/users/${uid}/docs/sf2data`), (doc) => {
			if (doc.exists()) {
				const data = doc.data() as Partial<StabfishAccountData>;
				this.updateTeam(data.teamId || '');
				userx.updateUserDoc({ ...userx.userDoc, ...data });
			}
			readDoc();
		});
		this.unsubUserInventoryDocListener = onSnapshot(dbdoc(db, `games/stabfish2/users/${uid}/docs/sf2inventory`), (doc) => {
			if (doc.exists()) {
				const data = doc.data() as Partial<StabfishAccountInventory>;
				userx.updateUserInventory({ ...userx.inventory, ...data });
			}
			readDoc();
		});
		this.unsubUserSettingsDocListener = onSnapshot(dbdoc(db, `games/stabfish2/users/${uid}/docs/sf2settings`), (doc) => {
			if (doc.exists()) {
				const partial = doc.data() as Partial<StabfishUserSettings>;
				userx.updateUserSettings({ ...userx.userSettings, ...partial });
			} else {
				// let client know doc has read
				userx.updateUserSettings({ ...userx.userSettings });
			}
			readDoc();
		});

	}
}


const fb = new StabfishFirebaseSocket();
export default fb;
(window as any).fb = fb;
